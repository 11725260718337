import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import Newcode from "./Newcode";
import StateList from "../../common/StateList";
import { deleteAddress } from "../../../utils/session_data";
import axios from "axios";
import { awsUrl } from "../../../config";
let monoStates = require("../../../utils/mono_states.json");

class CompLoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInfo: {
        state: {},
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
      },
    };
  }

  componentDidMount() {
    this.setState({ locationInfo: this.props.locationInfo });
  }

  componentDidUpdate() {
    if (this.props.locationInfo !== this.state.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
  }

  addClassCode = (number, classCodesInfo, updateLocationInfo) => {
    let codeCount = Object.keys(classCodesInfo).reduce((prev, val) => {
      return Math.max(Number(val), Number(prev));
    });
    updateLocationInfo(null, number, null, Number(codeCount) + 1);
  };

  getMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const { effectiveDate, updateLocationError } = this.props;
    const {
      ft,
      pt,
      payroll,
      classCodeDescription,
    } = locationInfo.classCodesInfo[addressChildNo];
    const state = locationInfo.state.value.toLowerCase();
    const zip = locationInfo.zipCode.value;

    let classCode = classCodeDescription.value.split(":")[0];

    let _payroll_value;

    try {
      if (payroll?.value?.includes("$") && payroll?.value?.split("$")?.[1]) {
        _payroll_value = payroll.value
          .split("$")[1]
          .split(",")
          .join("");
      } else {
        _payroll_value = payroll.value.split(",").join("");
      }
    } catch (error) {}

    let data = {
      code: classCode,
      pt: pt.value.trim(),
      ft: ft.value.trim(),
      payroll: _payroll_value,
    };

    let bodyData = {
      employees: [data],
      state: state,
      date_from: effectiveDate.value.format("YYYY-MM-DD"),
      location: zip,
    };

    if (!monoStates.includes(state)) {
      axios
        .post(awsUrl + "/api/getMinMaxPremium", JSON.stringify(bodyData))
        .then((response) => {
          if (response.data === "Not eligible") {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              true
            );
          } else {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              false
            );
          }
        })
        .catch((error) => {
          console.log(error);
          updateLocationError(
            addressBlockNo,
            addressChildNo,
            "classCodeDescription",
            true
          );
        });
    }
  };

  handleMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const keyList = ["ft", "pt", "payroll", "classCodeDescription"];
    let allClassCodeVals;
    if (locationInfo && locationInfo.classCodesInfo[addressChildNo]) {
      allClassCodeVals = keyList.every((e) => {
        return (
          e in locationInfo.classCodesInfo[addressChildNo] &&
          locationInfo.classCodesInfo[addressChildNo][e].value &&
          locationInfo.classCodesInfo[addressChildNo][e].value.trim() !== ""
        );
      });
    }
    if (allClassCodeVals && locationInfo.state.value) {
      this.getMinMax(addressBlockNo, addressChildNo);
    }
  };

  conditional_Render_street = (value) => {
    let style = {};

    if (value == "ST" && window.screen.width > 992) {
      style = { width: "52%", paddingInline: "15px" };
      return style;
    }
    if (value == "ZI" && window.screen.width > 993) {
      style = { width: "11%", paddingInline: "15px" };
      return style;
    }
    if (value == "CT" && window.screen.width > 993) {
      style = { width: "14%", paddingInline: "15px" };
      return style;
    }
    if (value == "STATE" && window.screen.width > 993) {
      style = { width: "11%", paddingInline: "15px" };
      return style;
    }
    if (
      value == "ST" ||
      value == "CT" ||
      value == "ZI" ||
      (value == "STATE" && window.screen.width < 993)
    ) {
      style = { maxWidth: "100%", paddingInline: "15px" };
      return style;
    }
  };

  render() {
    const {
      setStateAndCity,
      updateLocationInfo,
      removeLocation,
      removeClassCode,
      updateClassCodesInfo,
      resetClassCodeDescriptionData,
      index,
      updateLocationError,
      locationCount,
      deleteLocation,
    } = this.props;

    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;

    return (
      <div id={"officeLoc" + number} className="mb-2">
        {/* <div className="row mb-3">
          <h6 className="col-sm-12">Loc {index} </h6>
        </div> */}
        <div className="row grey loc1street_margin">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "8%" }}
          >
            <p>Loc{index} </p>
          </div>

          <div style={this.conditional_Render_street("ST")} id="street">
            <Form.Group>
              <label for={"street-" + number} title="Street Address">
                <b className="font-family-montserrat-semi-bold">
                  Street Address
                </b>
              </label>
              <input
                id={"street-" + number}
                name="street"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "street")
                }
                value={locationInfo ? locationInfo.street.value : ""}
                style={
                  locationInfo && locationInfo.street.error
                    ? { outline: "none", borderColor: "red" }
                    : {}
                }
                placeholder="Street Address"
                autoComplete="off"
                onKeyDown={(event) => {
                  if (event.key === " " && event.target.selectionStart === 0) {
                    event.preventDefault();
                  }
                }}
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          <div style={this.conditional_Render_street("ZI")} id="zip">
            <Form.Group>
              <label
                for={"zipCode-" + number}
                className={locationInfo.state.value == "CA" ? "mandatory" : ""}
                title="Zip Code"
              >
                <b className="font-family-montserrat-semi-bold">Zip</b>
              </label>
              <NumberFormat
                name="zip"
                id={"zipCode" + number}
                format="#####"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "zipCode")
                }
                value={locationInfo ? locationInfo.zipCode.value : ""}
                style={
                  locationInfo && locationInfo.zipCode.error
                    ? { outline: "none", borderColor: "red" }
                    : {}
                }
                onBlur={(event) => setStateAndCity(event.target.value, number)}
                placeholder="Zip"
                autoComplete="off"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>

          <div style={this.conditional_Render_street("CT")} id="city">
            <Form.Group>
              <label
                for={"cityName-" + number}
                className={locationInfo.state.value == "CA" ? "mandatory" : ""}
                title="City"
              >
                <b className="font-family-montserrat-semi-bold">City</b>
              </label>
              <input
                id={"cityName-" + number}
                name="cityName"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "cityName")
                }
                value={locationInfo ? locationInfo.cityName.value : ""}
                style={
                  locationInfo && locationInfo.cityName.error
                    ? { outline: "none", borderColor: "red" }
                    : {}
                }
                placeholder="City Name"
                autoComplete="off"
                onKeyDown={(event) => {
                  if (event.key === " " && event.target.selectionStart === 0) {
                    event.preventDefault();
                  }
                }}
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          <div style={this.conditional_Render_street("State")} id="state">
            <Form.Group>
              <label className="mandatory" title="State">
                <b className="font-family-montserrat-semi-bold">State</b>
              </label>
              <StateList
                state={locationInfo ? locationInfo.state.value : null}
                addressBlockNo={number}
                locationInfo={locationInfo}
                updateLocationInfo={updateLocationInfo}
                resetClassCodeDescriptionData={resetClassCodeDescriptionData}
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          {/* <div style={{ width: "14%", paddingInline: "15px" }} id="suta">
            <Form.Group>
              <label
                for={"suta-" + number}
                title="Requested SUTA %"
                style={{ display: "flex", marginTop: "-1.5rem" }}
              >
                <b className="font-family-montserrat-semi-bold">
                  Requested SUTA %
                </b>
              </label>
              <NumberFormat
                id={"suta-" + number}
                name="suta"
                type="text"
                disabled={locationInfo.suta.disabled ? true : false}
                fixedDecimalScale = {true}
                decimalScale = {2}
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "suta")
                }
                value={locationInfo ? locationInfo.suta.value : ""}
                style={
                  locationInfo && locationInfo.suta.error
                    ? { borderColor: "red", textAlign: "center" }
                    : locationInfo.suta.disabled
                    ? { backgroundColor: "#c2c4c5", textAlign: "center" }
                    : { textAlign: "center" }
                }
                placeholder="SUTA %"
                suffix="%"
                autoComplete="off"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div> */}
          {locationCount > 1 && (
            <div
              className="address-delete"
              style={{
                width: "4%",
                display: "flex",
                alignItems: "center",
                marginTop: "-1.2rem",
              }}
            >
              <button
                type="button"
                onClick={(event) => {
                  deleteLocation(number);
                }}
                className="rmvLoc btnInvisible transpButton"
              >
                <img
                  className="address_delete_btn"
                  onClick={(event) => deleteAddress(number)}
                  src={require("../../../images/delete4.png")}
                  alt=""
                />
              </button>
            </div>
          )}
        </div>
        <div className="">
          {false &&
            locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo && (
              <div className="row mb-3">
                <h6 className="col-sm-12">
                  <b>Class Code Information</b>
                </h6>
              </div>
            )}
          {locationInfo &&
            locationInfo.classCodesInfo &&
            Object.keys(locationInfo.classCodesInfo).map((e) => {
              return (
                <Newcode
                  key={e}
                  state={locationInfo.state.value}
                  addressChildNo={e}
                  addressBlockNo={number}
                  removeClassCode={removeClassCode}
                  classCodesInfo={locationInfo.classCodesInfo}
                  updateLocationError={updateLocationError}
                  updateClassCodesInfo={updateClassCodesInfo}
                  handleMinMax={this.handleMinMax}
                />
              );
            })}
          {locationInfo && locationInfo.classCodesInfo && (
            <div className="row add_classcode_margin">
              <div style={{ width: "6%", paddingInline: "15px" }}></div>
              <div className="calculator">
                <div>
                  <button
                    type="button"
                    className="btnInvisible addLoc transpButton p-0"
                    onClick={() =>
                      this.addClassCode(
                        number,
                        locationInfo.classCodesInfo,
                        updateLocationInfo
                      )
                    }
                  >
                    <img src={require("../../../images/plus4.png")} />{" "}
                    <b>Add class code</b>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CompLoc;
