// @ts-nocheck
import React, { FC, useEffect, useState, Component } from "react";
import Loader from "../../component/common/NewLoader";
import "./NewRates.scss";
import axios from "axios";
import { awsUrl2 } from "../../config";
import {
  ProgramCarrierDto,
  StatesDto,
  GetsRatesRequestDto,
  ProgramsList,
  separator,
  NewRatesDto,
  EmodDataDto,
  ChildrenLocDto,
} from "./NewRatesDtos";
import {
  NewRatesClassCodes,
  NewRatesModel,
  NewRatesStates,
  SelectOptions,
  ISideSliderProps,
  PopUpOptions,
} from "./NewRatesModel";
import { ClassCodeDropDown } from "./ClassCodeDropDown";
import Select, { GroupBase, OptionsOrGroups, SingleValue } from "react-select";
import { Option } from "react-dropdown";
import PlusIcon from "../../images/plus4.png";
import StatesData from "../../utils/state_city.json";
import RemoveIcon from "../../images/delete4.png";
import Header from "../../component/common/Header";
import Popup from "../../component/common/Popup";
import { CSVLink } from "react-csv";

// @ts-ignore
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// @ts-ignore
import DatePicker from "react-datepicker";
import { Auth } from "aws-amplify";

import EmodQuestions from "../../component/subcompo/sections-quote/EmodQuestions";
import toast, { Toaster } from "react-hot-toast";

import { getUserType } from "../../utils/getUserType";

import {
  carrierPeoProgramList,
  programMapping,
  carrierMapping
} from "../../utils/carrierList.json";
import SideTab from "../common/SideTab";

const uuidv4 = require("uuid/v4");

const moment = require("moment");
const ismobile = window.screen.width < 900;
const isTablet = window.innerWidth >= 900 && window.innerWidth < 1250;
let fontsizing = window.screen.width < 600 ? "fs-7" : "fs-6";
const PROGRAM_DISPLAY_COUNT = ismobile || isTablet ? Infinity : 4;
const colorMap = {
  approved: "#17B169",
  included: "#17B169",
  excluded: "#EF0107",
  rejected: "#EF0107",
  restricted: "#FFC72C",
};

export const NewRates: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [programCarrier, setProgramCarrier] = useState<ProgramCarrierDto[]>();
  const [tempSelectedProgram, setTempSelectedProgram] = useState<
    SelectOptions[]
  >();
  const [selectedProgram, setSelectedProgram] = useState<SelectOptions[]>();
  const [prevSelectedProgram, setPrevSelectedProgram] = useState<
    SelectOptions[]
  >();
  const [latestState, setLatestState] = useState<string | undefined>();
  const [programCarrier2, setProgramCarrier2] = useState<SelectOptions[]>();
  const [states, setState] = useState<StatesDto[]>();
  const [newRatesModel, setNewRatesModel] = useState<NewRatesModel>(
    new NewRatesModel()
  );
  const [programCarrierOptions, setProgramCarrierOptions] = useState<
    OptionsOrGroups<Option, GroupBase<Option>>
  >([]);
  const [statesOptions, setStatesOptions] = useState<
    OptionsOrGroups<Option, GroupBase<Option>>
  >([]);
  const [wasAllSelected, setWasAllSelected] = useState(false);
  const [leftIndex, setLeftIndex] = useState<number>(0);
  const [rightIndex, setRightIndex] = useState<number>(
    PROGRAM_DISPLAY_COUNT - 1
  );
  const [listToDisplay, setListToDisplay] = useState<any>([]);
  const [effectiveDate, setEffectiveDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [downloadData, setDownloadData] = useState<Array<Object>>([]);
  const [popupData, updatePopUpData] = useState<PopUpOptions>(
    new PopUpOptions()
  );
  const [emodStatesData, setEmodStatesData] = useState<EmodDataDto>({});
  const [quoteToolError, setQuoteToolError] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [peoCarrierProgramMap, setPeoCarrierProgramMap] = useState<EmodDataDto>(
    {}
  );

  const [selectedPeo, setSelectedPeo] = useState<string>("");
  const [selectedCarrierList, setSelectedCarrierList] = useState<Array<string>>(
    []
  );
  const [carrierList, setCarrierList] = useState<Array<string>>([]);
  const [downloadDisable, setDownloadDisable] = useState(false)
  const [peoList, setPeoList] = useState<Array<Object>>([]);
  const [reverseCarrierMap, setReverseCarrierMap] = useState<EmodDataDto>({});
  const [carrierEligibility, setCarrierEligibility] = useState<any>(
    JSON.parse(sessionStorage.getItem("carrierEligibility")!)
  );
  const [programHeaderMap, setProgramHeaderMap] = useState({})
  const pm: any = programMapping;

  const getProgramCarrier = async (callback: Function) => {
    let { userType, peoList } = await getUserType();

    if (peoList.length === 0) return;

    let _programCarrier: ProgramCarrierDto[] = [];
    let _programCarrier2 = [new SelectOptions("all", "Select All")];

    let peoCarrierProgramMap: EmodDataDto = {};

    let _revCarrMap: EmodDataDto = {};

    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;
      if (data.disableCarrier) continue;

      if (data.range) {
        let expectedStartDate = moment(effectiveDate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      let value = data.peo;
      // value = value
      //   .toLowerCase()
      //   .split(" ")
      //   .join("");

      if (
        peoList.some(
          (peo: EmodDataDto) =>
            peo.value === value || (peo.value === "demo" && value === "gms")
        )
      ) {
        _programCarrier.push(
          new ProgramCarrierDto(value, data.program, data.carrier)
        );
        _programCarrier2.push(
          new SelectOptions(`${value}-----${data.carrier}`, data.program)
        );

        if (!(value in peoCarrierProgramMap)) peoCarrierProgramMap[value] = {};

        if (!(data.carrier in peoCarrierProgramMap))
          peoCarrierProgramMap[value][data.carrier] = data.program;

        _revCarrMap[data.carrier] = data.actualCarrierName;
      }
    }

    setReverseCarrierMap(_revCarrMap);
    setProgramCarrier2(_programCarrier2);
    setProgramCarrier(_programCarrier);
    setPeoCarrierProgramMap(peoCarrierProgramMap);


    setPeoList(peoList);

    if (userType === "peo" && peoList.length > 0) {
      setSelectedPeo(peoList[0].value);
    }
    setIsLoading(false);

    callback();

    // let isLoggedIn,
    //   userGroups = [];
    // try {
    //   isLoggedIn = await Auth.currentAuthenticatedUser();

    //   userGroups =
    //     isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
    // } catch (error) {}
    // if (userGroups.length === 0) return;
    // try {
    //   console.log("get program carrier..");
    //   const response = await axios.post(
    //     awsUrl2 + "/api/getProgramCarrier",
    //     JSON.stringify({ groups: userGroups })
    //   );
    //   console.dir(response.data);

    //   if (response && response.status == 200 && response.data) {
    //     let _programCarrier: ProgramCarrierDto[] = [];
    //     let _programCarrier2 = [new SelectOptions("all", "Select All")];
    //     for (let _data of response.data) {
    //       _programCarrier.push(
    //         new ProgramCarrierDto(
    //           _data.program,
    //           _data.display_name,
    //           _data.carrier
    //         )
    //       );
    //       _programCarrier2.push(
    //         new SelectOptions(
    //           `${_data.program}-----${_data.carrier}`,
    //           _data.display_name
    //         )
    //       );
    //     }
    //     setProgramCarrier2(_programCarrier2);
    //     setProgramCarrier(_programCarrier);
    //   }
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const loadStates = () => {
    try {
      console.log("get states..");
      let _states: StatesDto[] = [];
      for (let _data of StatesData)
        _states.push(new StatesDto(_data.State, _data.Abbrev, _data.Code));
      setState(_states);
    } catch (e) {
      console.log(e);
    }
  };

  const setSelectedProgramCarrier = (value: any) => {
    let isAllSelected = false;
    let selectAllIndex = 0;
    for (let _data of value) {
      if (_data.value === "all") {
        isAllSelected = true;
        break;
      }
      selectAllIndex++;
    }
    if (isAllSelected) {
      if (programCarrier2?.length === value.length + 1 && wasAllSelected) {
        setWasAllSelected(false);
        let valueClone = JSON.parse(JSON.stringify(value));
        valueClone.splice(selectAllIndex, 1);
        setTempSelectedProgram(valueClone);
      } else {
        setWasAllSelected(true);
        setTempSelectedProgram(programCarrier2);
      }
    } else {
      if (wasAllSelected) {
        setTempSelectedProgram([]);
      } else {
        setTempSelectedProgram(value);
      }
      setWasAllSelected(false);
    }
  };

  const setCarrierEligibilityHelper = (raterData) => {
    let carrierEligibility = {};
    for (const value of raterData) {
      let eligibility_per_index = value["carrier_eligibility"];
      let state = value["state"]["value"];
      let code_desc = value["classCodes"][0]["classCode"]["label"];
      if (!carrierEligibility[state]) carrierEligibility[state] = {};
      carrierEligibility[state][code_desc] = eligibility_per_index;
    }
    setCarrierEligibility(carrierEligibility);
  };

  const setSelectedState = (value: any, index: number) => {
    console.log("selected state:", value, index);
    (value as SelectOptions).value = (value as SelectOptions).value.toLowerCase();
    let state = newRatesModel.states;
    state[index].state = value;
    setNewRatesModel((prevState) => {
      state[index].classCodes[0].classCode = new SelectOptions();
      return {
        programs: prevState.programs,
        states: [...state],
      };
    });
  };

  const addWorkersCompCode = (stateIndex: number) => {
    console.log("adding workers comp code...", stateIndex);
    if (newRatesModel && newRatesModel.states.length > 0) {
      let classCodes = [
        ...newRatesModel.states[stateIndex].classCodes,
        new NewRatesClassCodes(),
      ];
      newRatesModel.states[stateIndex].classCodes = classCodes;
      setNewRatesModel((prevState) => ({
        programs: prevState.programs,
        states: newRatesModel.states,
      }));
    }
  };

  const addState = () => {
    console.log("adding state...");
    if (newRatesModel) {
      let ratesStates = new NewRatesStates();
      if (latestState) {
        ratesStates = new NewRatesStates(
          new SelectOptions(latestState, latestState.toUpperCase())
        );
      }
      setNewRatesModel((prevState) => ({
        programs: prevState.programs,
        states: [...newRatesModel.states, ratesStates],
      }));
    }
  };

  const removeState = (stateIndex: number) => {
    if (newRatesModel) {
      const state = newRatesModel.states;
      state.splice(stateIndex, 1);
      setNewRatesModel((prevState) => ({
        programs: prevState.programs,
        states: state,
      }));
    }
  };

  const removeClassCode = (stateIndex: number, classCodeIndex: number) => {
    if (newRatesModel) {
      const state = newRatesModel.states;
      console.log("before update state", state);
      state[stateIndex].classCodes.splice(classCodeIndex, 1);
      console.log(
        "state index",
        stateIndex,
        " class code index",
        classCodeIndex
      );
      console.log("updated state", state);
      setNewRatesModel((prevState) => ({
        programs: prevState.programs,
        states: state,
      }));
    }
  };

  const updateEmodInfo = (state: any, fieldName: any, value: any) => {
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    let emodStatesDataCopy = { ...emodStatesData };

    if (dataFieldNames.includes(fieldName)) {
      emodStatesDataCopy[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesDataCopy[state][fieldName] = value;
    }

    // console.log("state => emodStatesDataCopy: ", emodStatesDataCopy);

    setEmodStatesData(emodStatesDataCopy);
  };

  const getRates = (
    stateIndex: number,
    classCode: string,
    description: string
  ) => {
    return new Promise(async (accept, reject) => {
      try {
        //console.log(selectedProgram);
        let _tempSelecedProgram = selectedProgram
          ? JSON.parse(JSON.stringify(selectedProgram))
          : null;
        let indx = 0;
        if (_tempSelecedProgram) {
          for (let _data of _tempSelecedProgram) {
            if (_data.value === "all") break;
            indx++;
          }
          _tempSelecedProgram.splice(indx, 1);
        }

        var request = new GetsRatesRequestDto(
          newRatesModel.states[stateIndex].state.value.toLowerCase(),
          effectiveDate,
          classCode,
          description,
          new ProgramsList().getPrograms(_tempSelecedProgram),
          emodStatesData
        );
        console.log("get rates..", request);
        const response = await axios.post(
          awsUrl2 + "/api/getNetRateAndManualrate",
          request
        );
        console.log("rates...", response.data);
        if (response.data) {
          let result = {}
          let obj = response.data;
          for (let mainKey in obj) {
            for (let subKey in obj[mainKey]) {
              result[(`${mainKey}-${carrierMapping[subKey]}`).toLocaleLowerCase()] = obj[mainKey][subKey].latestUploadDate;
            }
          }
          setProgramHeaderMap(result)
        } else {
          setProgramHeaderMap({})
        }
        console.log("selected programs...", _tempSelecedProgram);
        if (response && response.data && Object.keys(response.data).length) {
          console.log("new rates...", newRatesModel);
          const _state = newRatesModel.states;
          if (_tempSelecedProgram) {
            newRatesModel.states.forEach((itemState, stateIndex) => {
              if (
                itemState.state.value.toLowerCase() ==
                request.state.toLowerCase()
              ) {
                newRatesModel.states[stateIndex].classCodes.forEach(
                  (item, ratesIndex) => {
                    if (item.classCode.value === request.classCode) {
                      let _newRatesResponse: NewRatesDto[] = [];

                      for (let _data of _tempSelecedProgram) {
                        let _splitvalue = _data.value.split(separator);
                        let _responseData =
                          response.data[_splitvalue[0]][_splitvalue[1]];
                        let _newRates = new NewRatesDto();
                        _newRates.program = _data.label;
                        _newRates.manualRate = _responseData["manualRate"];
                        _newRates.netRate = _responseData["netRate"];
                        _newRates.fundRate = _responseData["fundRate"];
                        _newRates.carrier = _splitvalue[1];
                        _newRatesResponse.push(_newRates);
                      }
                      _state[stateIndex].classCodes[
                        ratesIndex
                      ].newRatesResponse = _newRatesResponse;
                    }
                  }
                );
              }
            });
          }
          console.log("new rates updating...");
          setNewRatesModel((prevState) => ({
            programs: prevState.programs,
            states: _state,
          }));
          accept("");
        } else {
          reject("error in getting rates");
        }
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const compareSelectedPrograms = (
    list1: SelectOptions[] | undefined,
    list2: SelectOptions[] | undefined
  ) => {
    let isSame = false;
    let objInList;
    if (!list1 || (list1 && list1.length === 0 && !list2)) {
      isSame = true;
    } else if (list1?.length === list2?.length) {
      for (let list1Item of list1) {
        objInList = false;
        for (let list2Item of list2) {
          if (
            list1Item.value === list2Item.value &&
            list1Item.label === list2Item.label
          ) {
            objInList = true;
            isSame = true;
            break;
          }
        }
        if (!objInList) {
          isSame = false;
          break;
        }
      }
    } else {
      isSame = false;
    }

    return isSame;
  };

  const fetchAndFillData = () => {
    try {
      let data = sessionStorage.getItem("rates_data");
      if (data) {
        let data2 = JSON.parse(data);
        setCarrierEligibilityHelper(data2);
        let state_newRates_list: NewRatesStates[] = [];

        data2.forEach((e: any) => {
          let class_code_newRates_list: NewRatesClassCodes[] = [];

          e.classCodes.forEach((cc: any) => {
            let class_code_newRates = new NewRatesClassCodes(
              cc.classCode.value && cc.classCode.label
                ? new SelectOptions(cc.classCode.value, cc.classCode.label)
                : new SelectOptions(),
              [new NewRatesDto()]
            );
            class_code_newRates_list.push(class_code_newRates);
          });

          let state_newRates = new NewRatesStates(
            new SelectOptions(e.state.value, e.state.label),
            class_code_newRates_list
          );
          state_newRates_list.push(state_newRates);
        });
        let new_rates_model = new NewRatesModel(
          new SelectOptions(),
          state_newRates_list
        );
        setNewRatesModel(new_rates_model);
      }
    } catch (error) {
      console.log("err: ", error);
    }
  };

  const checkUploadError = () => {
    let displayErrorPopup = sessionStorage.getItem("invalid_input") === "true";
    if (displayErrorPopup) {
      updatePopUpData({
        show: true,
        title: "Error!",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              <p>
                Please check upload file for incorrect state - class code
                combinations**
              </p>
              <p style={{ fontSize: "12px", textAlign: "left" }}>
                **To Proceed -
              </p>
              <ol style={{ fontSize: "12px", textAlign: "left" }}>
                <li>
                  Please go to the UI and append the correct state - class code
                  combination as necessary OR
                </li>
                <li>
                  Re-upload the excel with the correct state - class code
                  combinations
                </li>
              </ol>
            </span>
          </>
        ),
        acceptBtn: "Okay",
        disableNoButton: true,
        acceptBtnCallback: async () => {
          sessionStorage.removeItem("invalid_input");
        },
      });
    }
  };

  const getMcpStates = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let statesObj = newRatesModel.states;
        let stateList = [];

        for (let obj of statesObj) {
          if (obj?.state?.value) stateList.push(obj.state.value);
        }

        let emodStatesDataCopy = {
          ...emodStatesData,
        };

        for (let state in emodStatesDataCopy) {
          if (!stateList.includes(state)) {
            delete emodStatesDataCopy[state];
          }
        }

        let _tempSelecedProgram = selectedProgram
          ? JSON.parse(JSON.stringify(selectedProgram))
          : null;

        let indx = 0;
        if (_tempSelecedProgram) {
          for (let _data of _tempSelecedProgram) {
            if (_data.value === "all") break;
            indx++;
          }
          _tempSelecedProgram.splice(indx, 1);
        }
        let programList = new ProgramsList().getPrograms(_tempSelecedProgram);

        if (programList.length === 0) return;
        // let response;
        // response = await axios.post(awsUrl2 + "/api/raterMcpStateData", {
        //   stateList,
        //   programList,
        // });

        // if (response?.data?.length > 0) {
        //   for (let state of response.data) {
        //     if (!(state in emodStatesDataCopy)) {
        //       emodStatesDataCopy[state] = {
        //         rate1: {
        //           value: "",
        //         },
        //         date1: {
        //           value: moment(),
        //         },
        //         response: {
        //           value: false,
        //         },
        //       };
        //     }
        //   }
        // } else if (response?.data?.length === 0) {
        //   emodStatesDataCopy = {};
        // }
        setEmodStatesData(emodStatesDataCopy);
        resolve("success");
      } catch (error) {
        console.log("error in getMCP states");
      }
    });
  };

  const fetchAndFillDashboardData = () => {
    let newRatesModel = sessionStorage.getItem("newRatesModel");
    let emodStatesData = sessionStorage.getItem("emodStatesData");
    let effectiveDate = sessionStorage.getItem("effectiveDate");
    let selectedProgram = sessionStorage.getItem("selectedProgram");
    let name = sessionStorage.getItem("name");
    let peo = sessionStorage.getItem("selectedPeo");
    let carrList = [];

    try {
      carrList = JSON.parse(
        sessionStorage.getItem("selectedCarrierList") || "[]"
      );
    } catch (err) {
      console.log(err);
      carrList = [];
    }

    if (
      newRatesModel &&
      emodStatesData &&
      effectiveDate &&
      selectedProgram &&
      peo &&
      carrList?.length
    ) {
      try {
        setEffectiveDate(effectiveDate);
        setSelectedPeo(peo.toLowerCase());
        setNewRatesModel(JSON.parse(newRatesModel));
        setSelectedCarrierList(carrList);
        setEmodStatesData(JSON.parse(emodStatesData));
        setSelectedProgram(JSON.parse(selectedProgram));
        setTempSelectedProgram(JSON.parse(selectedProgram));

        if (name) {
          setName(name);
        }
      } catch (error) { }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getProgramCarrier(fetchAndFillDashboardData);
    loadStates();
    fetchAndFillData();
    checkUploadError();
  }, []);

  useEffect(() => {
    getProgramCarrier(() => {
      console.log("Setting PEO program carrier on the basis of effective date");
    });
  }, [selectedPeo, effectiveDate]);

  useEffect(() => {
    let _carrierList = [],
      _carrierList2 = [];
    let expectedStartDate = moment(effectiveDate);
    let selectedPeoClone = selectedPeo;
    if (selectedPeoClone === "demo") {
      selectedPeoClone = "gms";
    }

    if (selectedPeoClone in peoCarrierProgramMap) {
      for (let carrier in peoCarrierProgramMap[selectedPeoClone]) {
        let __programValue = peoCarrierProgramMap[selectedPeoClone][carrier];
        _carrierList2.push(carrier);
        if (
          selectedPeoClone === "insurecomp" &&
          __programValue?.indexOf("HEMIC") > -1
        )
          continue;
        _carrierList.push(carrier);
      }
    }

    // setSelectedProgram([]);
    setEmodStatesData({});
    setCarrierList(_carrierList2);
    setSelectedCarrierList(_carrierList);
    //additional code for handling autoselection of all carriers when peo is selected
    let _selectedProgramCarrier = [];
    if (_carrierList.length > 0) {
      let _peoObject = peoCarrierProgramMap?.[selectedPeoClone];
      for (let _carr of _carrierList) {
        if (_carr in _peoObject) {
          let _programValue = _peoObject[_carr];
          _selectedProgramCarrier.push(
            new SelectOptions(`${selectedPeoClone}-----${_carr}`, _programValue)
          );
        }
      }
    }
    setSelectedProgramCarrier(_selectedProgramCarrier);
    setSelectedProgram(_selectedProgramCarrier);
  }, [peoCarrierProgramMap]);

  useEffect(() => {
    if (selectedCarrierList.length > 0) {
      let _selectedProgramCarrier = [];

      let selectedPeoClone = selectedPeo;
      if (selectedPeoClone === "demo") {
        selectedPeoClone = "gms";
      }

      let _peoObject = peoCarrierProgramMap[selectedPeoClone];
      for (let _carr of selectedCarrierList) {
        if (_carr && _peoObject && _carr in _peoObject) {
          let _programValue = _peoObject[_carr];
          _selectedProgramCarrier.push(
            new SelectOptions(`${selectedPeoClone}-----${_carr}`, _programValue)
          );
        }
      }
      setSelectedProgramCarrier(_selectedProgramCarrier);
    }
  }, [selectedCarrierList]);

  useEffect(() => {
    if (programCarrier) {
      setProgramCarrierOptions(
        new ProgramCarrierDto().getOptions(programCarrier)
      );
      //  console.log("program carrier options:", programCarrierOptions);
    }
  }, [programCarrier]);

  useEffect(() => {
    if (states) {
      setStatesOptions(new StatesDto().getOptions(states));
      console.log("states options:", statesOptions);
    }
  }, [states]);

  useEffect(() => {
    if (newRatesModel) {
      console.log("new rates model:", newRatesModel);
      try {
        if (newRatesModel?.states && newRatesModel?.states?.length > 0) {
          let stateDataList = newRatesModel.states.slice().reverse();
          for (let stateData of stateDataList) {
            if (stateData?.state?.value && stateData?.state?.value !== "0") {
              setLatestState(stateData?.state?.value);
              break;
            }
          }
        }
      } catch (error) {
        console.log("error while setting latest state");
      }
    }
  }, [newRatesModel]);

  useEffect(() => {
    let listToDisplay = [];
    let copySelectedProgram = selectedProgram?.map((ele) => {
      return { ...ele };
    });

    if (copySelectedProgram) {
      for (let indx in copySelectedProgram) {
        if (copySelectedProgram[Number(indx)].label === "Select All") {
          copySelectedProgram.splice(Number(indx), 1);
          break;
        }
      }

      if (
        copySelectedProgram &&
        (rightIndex - leftIndex + 1 >= copySelectedProgram.length ||
          copySelectedProgram.length <= leftIndex)
      ) {
        setLeftIndex(0);
        setRightIndex(PROGRAM_DISPLAY_COUNT - 1);
      }

      for (let indx in copySelectedProgram) {
        if (Number(indx) >= leftIndex && Number(indx) <= rightIndex) {
          listToDisplay.push(copySelectedProgram[Number(indx)].label);
        }
      }
      setListToDisplay(listToDisplay);
    }
  }, [leftIndex, rightIndex, selectedProgram]);

  useEffect(() => {
    getMcpStates();
  }, [selectedProgram]);

  let handleClassCodeCheck = (newRatesModel) => {
    let classCodeStatus;
    if (newRatesModel.states.length > 1) {
      for (let i = 0; i < newRatesModel.states.length; i++) {
        classCodeStatus = Number(
          newRatesModel?.["states"]?.[i]["classCodes"][0]?.classCode?.value
        );
        if (!classCodeStatus) {
          break;
        }
      }
    } else {
      classCodeStatus = Number(
        newRatesModel?.["states"]?.[0]["classCodes"][0].classCode?.value
      );
    }

    return classCodeStatus;
  }

  useEffect(() => {
    let classCodeStatus;
    if (newRatesModel) {
      classCodeStatus = handleClassCodeCheck(newRatesModel)
    }

    if (
      selectedPeo &&
      selectedCarrierList.length > 0 &&
      latestState &&
      classCodeStatus
    ) {
      setDownloadDisable(true)
    } else {
      setDownloadDisable(false)
    }

    if (
      selectedPeo ||
      selectedCarrierList.length > 0 ||
      latestState
    ) {
      setQuoteToolError("")
    }

  }, [newRatesModel, selectedPeo, selectedCarrierList, latestState])

  const getAllRates = () => {
    setIsLoading(true);
    let promiseList: any = [];
    newRatesModel.states.forEach((state, stateIndex) => {
      state.classCodes.forEach((classCodeData) => {
        let classCode = (classCodeData.classCode as SelectOptions).value;
        let description = (classCodeData.classCode as SelectOptions).label
          .split(":")
          .slice(1)
          .join(":")
          .trim();
        if (classCode)
          promiseList.push(
            getRates(stateIndex, classCode, description).catch((error) =>
              console.log("error in getting rates", error)
            )
          );
      });
    });

    Promise.all(promiseList).then((response) => {
      setIsLoading(false);
    });
  };

  const prepareDownloadData = () => {
    let response: Array<Object> = [];
    newRatesModel.states.forEach((stateData) => {
      let rowData: any = {};
      let state = (stateData.state as SelectOptions).label;
      stateData.classCodes.forEach((classCodeData) => {
        let classCode = (classCodeData.classCode as SelectOptions).label
          .split(":")[0]
          .trim();
        let description = (classCodeData.classCode as SelectOptions).label
          .split(":")
          .slice(1)
          .join(":")
          .trim();
        rowData = {
          State: state,
          "Class Code": classCode,
          Description: description,
        };
        classCodeData.newRatesResponse.forEach((programData) => {
          let program = (programData as NewRatesDto).program;
          let manualRate = (programData as NewRatesDto).manualRate;
          let netRate = (programData as NewRatesDto).netRate;
          if (program !== "N/A") {
            rowData[
              `${selectedPeo === "demo"
                ? `Demo-${pm[program]?.carrier?.toUpperCase()}`
                : program
              } - Manual Rate`
            ] = manualRate;
            rowData[
              `${selectedPeo === "demo"
                ? `Demo-${pm[program]?.carrier?.toUpperCase()}`
                : program
              } - Net Rate`
            ] = netRate;
          }
        });
        response.push(rowData);
      });
    });

    setDownloadData(response);
  };

  const removeEmodStates = () => {
    let statesObj = newRatesModel.states;
    let stateList2 = [];
    let emodStatesDataCopy = { ...emodStatesData };

    for (let obj of statesObj) {
      if (obj?.state?.value) stateList2.push(obj.state.value);
    }

    for (let state in emodStatesDataCopy) {
      if (!stateList2.includes(state)) {
        delete emodStatesDataCopy[state];
      }
    }

    setEmodStatesData(emodStatesDataCopy);
  };

  useEffect(() => {
    setTimeout(getAllRates, 250);
  }, [selectedProgram, effectiveDate, carrierEligibility]);

  let isAllSelected = false;
  if (selectedProgram)
    for (let _data of selectedProgram) {
      if (_data.value === "all") isAllSelected = true;
    }

  //function to return the maximum key value in the childrenLoc
  const childrenLocMaxKey = (object: {}) => {
    let max = 0;
    for (let key in object) {
      max = Math.max(max, Number(key));
    }
    return Number(max + 1).toString();
  };

  //function to return class code info empty object
  const returnClassCodeInfo = (ccDesc: string = "") => {
    return {
      classCodeDescription: {
        error: false,
        value: ccDesc,
      },
      ft: {
        value: "",
      },
      payroll: {
        value: "",
      },
      pt: {
        value: "",
      },
    };
  };

  const success = (text: string) =>
    toast.success(text, {
      duration: 5000,
    });
  const error = (text: string) =>
    toast.error(text, {
      duration: 5000,
    });

  //save button
  const handleOnClickSave = async () => {
    try {
      let classCodeStatus = handleClassCodeCheck(newRatesModel)
      if (!name) {
        error("Name cannot be empty");
        return;
      }
      setIsLoading(true);
      let isLoggedIn = await Auth.currentAuthenticatedUser();

      let email_id = isLoggedIn.attributes.email;
      let id = name;
      if (
        name &&
        selectedPeo &&
        selectedCarrierList.length > 0 &&
        latestState &&
        classCodeStatus
      ) {
        axios
          .post(
            awsUrl2 + "/api/raterTableData/exists",
            JSON.stringify({
              email_id,
              id,
            })
          )
          .then(async (res) => {
            let ans = res.data;
            if (ans) {
              updatePopUpData({
                show: true,
                title: "Already Exists!",
                children: (
                  <div className="d-flex flex-row">
                    <span>Record name already exists.</span>
                    <span>Do you wish to overwrite it?</span>
                  </div>
                ),
                acceptBtn: "Yes",
                disableNoButton: false,
                acceptBtnCallback: async () => {
                  await saveDetails(email_id, id);
                },
                rejectBtnCallback: async () => {
                  setIsLoading(false);
                },
              });
            } else {
              await saveDetails(email_id, id);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            error("Error!");
          });
      } else {
        error("Please fill all the required fields");
        setIsLoading(false);
      }
    } catch (error) { }
  };

  //save to db
  const saveDetails = async (email_id: string, id: string) => {
    try {
      axios
        .post(
          awsUrl2 + "/api/raterTableData/insert",
          JSON.stringify({
            email_id,
            id,
            newRatesModel,
            effectiveDate,
            emodStatesData,
            selectedProgram,
            selectedPeo,
            selectedCarrierList,
            carrierEligibility,
            isRater: "true",
          })
        )
        .then((res) => {
          setIsLoading(false);
          success("Saved!");
        })
        .catch((err) => {
          setIsLoading(false);
          error("Error!");
        });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      error("Error!");
    }
  };

  //fuction to create the currProspect
  const handleOnClickQuoteToolBtn = () => {
    try {
      let childrenLoc: ChildrenLocDto = {};
      let key = 0;

      let classCodeStatus = handleClassCodeCheck(newRatesModel)

      //map to store state and its first occured index (in childrenLoc)
      let stateKeyMap: ChildrenLocDto = {};

      //empty company profile object
      let companyProfile = {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        expectedStartDate: { value: moment() },
        effectiveDate: { value: moment() },
        fein: {},
        street1: {},
        zipCode1: {},
        cityName1: {},
        state1: {},
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
      };

      //loop over ratesModel object to create FormCompPro compatible childreLoc object
      newRatesModel.states.forEach((stateObject) => {
        //state exists in stateKeyMap then append a new classCode inside classCodeInfo
        if (stateObject.state.value in stateKeyMap) {
          let availableKey = stateKeyMap[stateObject.state.value];

          let currentChildrenLoc = childrenLoc[availableKey];

          let currentChildrenLocClassCodeInfo =
            currentChildrenLoc.classCodesInfo;

          let currentMaxKey = childrenLocMaxKey(
            currentChildrenLocClassCodeInfo
          );

          childrenLoc[availableKey].classCodesInfo[
            currentMaxKey
          ] = returnClassCodeInfo(stateObject?.classCodes[0]?.classCode?.label);
        }
        //create new state in the stateKeyMap and childrenLoc
        else if (stateObject.state.label !== "--Select--") {
          stateKeyMap[stateObject.state.value] = Number(key).toString();

          childrenLoc[Number(key).toString()] = {
            cityName: {
              value: "",
            },
            classCodesInfo: {},
            key: Math.random(),
            number: key,
            state: {
              value: stateObject.state.label,
            },
            street: {
              value: "",
            },
            suta: {},
            zipCode: {
              value: "",
            },
          };

          childrenLoc[Number(key).toString()].classCodesInfo[
            "0"
          ] = returnClassCodeInfo(stateObject?.classCodes[0]?.classCode?.label);
          key++;
        }
      });

      //if childrenLoc object is created succesfully then create currProspect route to "/quote"
      if (childrenLoc &&
        Object.keys(childrenLoc).length > 0 &&
        selectedPeo &&
        selectedCarrierList.length > 0 &&
        latestState &&
        classCodeStatus
      ) {
        console.log("childrenO: ", childrenLoc);

        let peoDetails = {
          selectedPeo: "",
        };
        if (selectedPeo) {
          peoDetails = {
            selectedPeo,
          };
        }

        type carrierListType = {
          value: {
            label: string;
            value: string;
          }[];
          error: boolean;
        };

        let carrierList: carrierListType = {
          value: [],
          error: false,
        };
        if (selectedCarrierList) {
          let _carrierList: {
            label: string;
            value: string;
          }[] = [];

          let carrierSet = new Set();

          let hasCarrier = (list: string[], carr: string) => {
            // console.log(list);
            for (let ele of list) {
              if (ele === carr) return true;
            }
            return false;
          };

          for (let data of carrierPeoProgramList) {
            if (data.devRemoved && process.env.REACT_APP_MODE === "dev")
              continue;
            if (data.prodRemoved && process.env.REACT_APP_MODE === "prod")
              continue;
            if (data.disableCarrier) continue;

            if (data.range) {
              let expectedStartDate = moment(effectiveDate, "YYYY-MM-DD");
              let includeCarrier = false;
              for (let range of data.range) {
                if (!range.to && range.from) {
                  const fromDate = moment(range.from, "YYYY-MM-DD");

                  if (fromDate.isSameOrBefore(expectedStartDate)) {
                    includeCarrier = true;
                    break;
                  }
                }
                if (!range.from && range.to) {
                  const toDate = moment(range.to, "YYYY-MM-DD");
                  if (toDate.isAfter(expectedStartDate)) {
                    includeCarrier = true;
                    break;
                  }
                }
                if (range.to && range.from) {
                  const fromDate = moment(range.from, "YYYY-MM-DD");
                  const toDate = moment(range.to, "YYYY-MM-DD");

                  if (
                    expectedStartDate.isSameOrAfter(fromDate) &&
                    expectedStartDate.isBefore(toDate)
                  ) {
                    includeCarrier = true;
                    break;
                  }
                }
              }
              if (!includeCarrier) continue;
            }

            if (
              hasCarrier(selectedCarrierList, data.carrier) &&
              !carrierSet.has(data.carrier)
            ) {
              _carrierList.push({
                label: data.actualCarrierName,
                value: data.carrier,
              });
              carrierSet.add(data.carrier);
            }
          }
          carrierList.value = _carrierList;
        }

        sessionStorage.clear();
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify({
            companyProfile,
            childrenLoc,
            emodStatesData,
            peoDetails,
            carrierList,
          })
        );
        window.location.href = "/quote";
      } else {
        setQuoteToolError("Please Enter all mandatory fields")
      }
    } catch (error) {
      console.log("error ", error);
      setQuoteToolError("Error");
    }
  };

  let carrierListDisplay: any[] = [];

  carrierList.forEach((carr: string) => {
    let label =
      selectedPeo === "demo"
        ? pm[`GMS-${reverseCarrierMap[carr]}`]?.carrier?.toUpperCase()
        : reverseCarrierMap[carr];

    carrierListDisplay.push({ label, value: carr });
  });

  carrierListDisplay.sort((a: any, b: any) => {
    return a.label.localeCompare(b.label);
  });
  if (carrierListDisplay.length > 0) {
    carrierListDisplay.unshift({
      label: "Select All",
      value: "select_all",
    });
  }

  let selectedCarrierListDisplay: any[] = [];

  // selectedCarrierList.forEach((carr: string) => {
  //   let label =
  //     selectedPeo === "demo"
  //       ? pm[`GMS-${reverseCarrierMap[carr]}`]?.carrier?.toUpperCase()
  //       : reverseCarrierMap[carr];
  //   selectedCarrierListDisplay.push({
  //     label,
  //     value: carr,
  //   });
  // });

  for (let carr of selectedCarrierList) {
    let label =
      selectedPeo === "demo"
        ? pm[`GMS-${reverseCarrierMap[carr]}`]?.carrier?.toUpperCase()
        : reverseCarrierMap[carr];

    selectedCarrierListDisplay.push({
      label,
      value: carr,
    });
  }

  let peoListToDisplay: SelectOptions[] = [],
    selectedPeoToDisplay: SelectOptions = new SelectOptions();

  peoList.map((peo: EmodDataDto) => {
    peoListToDisplay.push(new SelectOptions(peo.value, peo.label));
    if (peo.value === selectedPeo)
      selectedPeoToDisplay = new SelectOptions(peo.value, peo.label);
  });

  let isSelectAll =
    carrierListDisplay.length === selectedCarrierListDisplay.length
      ? true
      : false;

  // console.log(
  //   "selectedPeoToDisplay",
  //   selectedPeoToDisplay,
  //   "peoListToDisplay",
  //   peoListToDisplay
  // );
  let programRange = (headers) => {
    let peo = headers.split("-")[0];
    let carrier = headers.split("-")[1];
    let program = headers.toLowerCase();

    if (carrier == "Midwest Employers Casualty") {
      headers = `${peo}-Midwest`
    }
    console.log("Suraj program", programHeaderMap)
    try {
      if (programHeaderMap[program]) {
        let date = moment(programHeaderMap[program]).format('MM-DD-YYYY')
        return (<p style={{ whiteSpace: "pre-wrap" }}>{`${headers} \n(${date})`}</p>)
      } else {
        return headers
      }
    } catch (err) {
      console.log("ERROR:", err)
      return headers
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="container-fluid newrates-container newrates-bg px-0 py-0">
        <div className="d-flex justify-content-between bg-white">
          <Header />
        </div>
        <div className="position-relative">
          {ismobile ? <SideTab /> : " "}
          <div className="mx-0 px-5 pt-3 pb-5 newrates-bg d-flex header-container selectprogram">
            <div
              className={`px-0 selectprogramone selected peo-dropdown-container ${ismobile || isTablet ? "row col" : " "
                }`}
            >
              <p
                className={` ${fontsizing} font-weight-bold mb-2 pt-3 ${ismobile || isTablet ? "col-4" : " "
                  } selcetprogramtoright`}
              >
                Distributing partner<span style={{ color: "red" }}>*</span>
              </p>

              <Select
                className={`${ismobile || isTablet ? "col-8" : " "}`}
                value={selectedPeoToDisplay}
                onChange={(e: EmodDataDto | null) => {
                  setSelectedPeo(e?.value);
                }}
                options={peoListToDisplay}
                isMulti={false}
                menuPlacement="auto"
              />
            </div>

            <div
              className={`px-0 selectprogramone  ${ismobile || isTablet ? "row col" : " "
                }`}
            >
              <p
                className={` ${fontsizing} font-weight-bold mb-2 pt-3 ${ismobile || isTablet ? "col-4" : " "
                  } selcetprogramtoright`}
              >
                Carrier<span style={{ color: "red" }}>*</span>
              </p>
              <div
                className={`multi-select-container   ${ismobile || isTablet ? "col-8" : " "
                  }`}
              >
                <ReactMultiSelectCheckboxes
                  className="react-select-checkbox "
                  classNamePrefix="react-select-checkbox"
                  getDropdownButtonLabel={() =>
                    isAllSelected
                      ? "All Selected"
                      : selectedCarrierListDisplay &&
                        selectedCarrierListDisplay.length > 0
                        ? isSelectAll
                          ? `${selectedCarrierListDisplay.length - 1} selected`
                          : `${selectedCarrierListDisplay.length} selected`
                        : "--Select--"
                  }
                  value={selectedCarrierListDisplay}
                  options={carrierListDisplay}
                  onChange={(val: any[]) => {
                    let _selectedCarrList1: any[] = [];
                    // val.forEach((e: EmodDataDto) => {
                    //   _selectedCarrList.push(e.value);
                    // });
                    // setSelectedCarrierList(_selectedCarrList);

                    if (
                      isSelectAll &&
                      val.some(
                        (item: EmodDataDto) => item.value === "select_all"
                      )
                    ) {
                      let _val = [];
                      for (let v of val) {
                        if (v.value !== "select_all") _val.push(v.value);
                      }

                      setSelectedCarrierList(JSON.parse(JSON.stringify(_val)));
                    } else if (
                      !isSelectAll &&
                      val.some(
                        (item: EmodDataDto) => item.value === "select_all"
                      )
                    ) {
                      let _selectedCarrList2: any[] = [];
                      for (let i = 0; i < carrierListDisplay.length; i++) {
                        _selectedCarrList2.push(carrierListDisplay[i].value);
                      }
                      // console.log(
                      //   _selectedCarrList2.length,
                      //   "_selectedCarrList2.length",
                      //   _selectedCarrList2
                      // );
                      setSelectedCarrierList(
                        JSON.parse(JSON.stringify(_selectedCarrList2))
                      );
                    } else if (
                      isSelectAll &&
                      !val.some(
                        (item: EmodDataDto) => item.value === "select_all"
                      )
                    ) {
                      setSelectedCarrierList([]);
                    } else {
                      let _selectedCarrList3: any[] = [];
                      for (let i = 0; i < val.length; i++) {
                        _selectedCarrList3.push(val[i].value);
                      }
                      setSelectedCarrierList(
                        JSON.parse(JSON.stringify(_selectedCarrList3))
                      );
                    }
                  }}
                  onBlur={() => {
                    setSelectedProgram(tempSelectedProgram);
                  }}
                />{" "}
              </div>
            </div>

            <div
              className={`px-0 selectprogramone  ${ismobile || isTablet ? "row col" : " "
                }`}
            >
              <p
                className={` ${fontsizing} font-weight-bold mb-2 pt-3 ${ismobile || isTablet ? "col-4" : " "
                  } selcetprogramtoright`}
              >
                Effective Date<span style={{ color: "red" }}>*</span>
              </p>
              <span
                id="rater-eff-date"
                className={`${ismobile || isTablet ? "col-8" : " "}`}
              >
                <DatePicker
                  className="datewidth"
                  selected={moment(effectiveDate, ["YYYY-MM-DD"])}
                  onChange={(e: any) => {
                    if (e) {
                      setEffectiveDate(moment(e).format("YYYY-MM-DD"));
                    }
                  }}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                />
              </span>
            </div>
          </div>

          <div className="gridingtherater">
            {!(ismobile || isTablet) ? (
              <div className="row no-gutters peonames">
                <div className="col-22 makingdisplaynone"></div>
                <div className="d-flex justify-content-center align-items-center col-1">
                  {selectedProgram &&
                    selectedProgram.length > PROGRAM_DISPLAY_COUNT && (
                      <button
                        className="d-flex justify-content-center align-items-center custom-left-right-switch btn btn-sm btn-info"
                        onClick={() => {
                          if (leftIndex) {
                            setLeftIndex(leftIndex - 1);
                            setRightIndex(rightIndex - 1);
                          }
                        }}
                        disabled={leftIndex === 0}
                      >
                        {"<"}
                      </button>
                    )}
                </div>
                <div className="col-7 row namesofpeo">
                  {selectedCarrierList?.length > 0 && listToDisplay.map((ele: any, eleInd: number) => {
                    let programDate = programRange(ele) ? ` (${programRange(ele)})` : ""

                    return (
                      <div
                        className="col-3 font-weight-bold peo-header-styling text-center listofPeo"
                        key={eleInd}
                        style={
                          eleInd === 0
                            ? { paddingRight: "35px", paddingLeft: "2px" }
                            : eleInd === 1
                              ? { paddingRight: "25px", paddingLeft: "12px" }
                              : eleInd === 2
                                ? { paddingRight: "16px", paddingLeft: "21px" }
                                : eleInd === 3
                                  ? { paddingRight: "5px", paddingLeft: "32px" }
                                  : {}
                        }
                      >
                        {selectedPeo === "demo"
                          ? `Demo-${pm[ele]?.carrier?.toUpperCase()}`
                          : programRange(ele)}
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-center align-items-center col-1">
                  {selectedProgram &&
                    selectedProgram.length > PROGRAM_DISPLAY_COUNT && (
                      <button
                        className="d-flex justify-content-center align-items-center custom-left-right-switch btn btn-sm btn-info"
                        onClick={() => {
                          if (rightIndex + 1 < selectedProgram.length) {
                            setLeftIndex(leftIndex + 1);
                            setRightIndex(rightIndex + 1);
                          }
                        }}
                        disabled={
                          !isAllSelected
                            ? rightIndex + 1 === selectedProgram.length
                            : rightIndex + 1 === selectedProgram.length - 1
                        }
                      >
                        {">"}
                      </button>
                    )}
                </div>
              </div>
            ) : (
              " "
            )}
            <div className="row px-5 py-3 newrates-bg pb-5 no-gutters rates-container peorates">
              <div className="divider pb-3"></div>
              {newRatesModel &&
                newRatesModel.states.length > 0 &&
                newRatesModel.states.map((state, stateIndex) => {
                  let displayHeaders: Boolean;

                  if (ismobile || isTablet) {
                    displayHeaders = true;
                  } else {
                    displayHeaders = stateIndex === 0 ? true : false;
                  }

                  return (
                    <div key={stateIndex} className="col-12">
                      {state.classCodes.map((classcode, classCodeIndex) => {
                        return (
                          <div
                            key={classCodeIndex}
                            className="row no-gutters stateandworkes "
                          >
                            <div
                              className={`${ismobile || isTablet ? "col-xl-12" : "col-3"
                                } addingpadding  `}
                            >
                              <div className="row w-100 align-items-end no-gutters statework">
                                <div
                                  className={`${ismobile || isTablet ? "col-12" : "col-sm-4"
                                    } pr-2 statework_oneline`}
                                >
                                  {displayHeaders && (
                                    <p
                                      className={`${fontsizing} font-weight-bold m-0 p-0 c ${ismobile || isTablet ? "col-4" : ""
                                        }  statetoleft`}
                                    >
                                      State<span style={{ color: "red" }}>*</span>
                                    </p>
                                  )}
                                  <Select
                                    styles={{
                                      singleValue: (provided) => {
                                        let color;
                                        if (
                                          !state?.state?.label ||
                                          state?.state?.label === "--Select--"
                                        ) {
                                          color = "hsl(0, 0%, 50%) !important";
                                        }
                                        return { ...provided, color };
                                      },
                                    }}
                                    value={state.state}
                                    onChange={async (e) => {
                                      setSelectedState(e, stateIndex);
                                      await getMcpStates();
                                    }}
                                    options={statesOptions}
                                    isMulti={false}
                                    className={`newrates-select ${ismobile || isTablet ? "col-8" : ""
                                      } `}
                                    menuPlacement="auto"
                                  />
                                </div>
                                <div
                                  className={` ${ismobile || isTablet
                                    ? "col-xl-12"
                                    : "col-sm-8"
                                    }  flex-column statework_two`}
                                >
                                  {displayHeaders && (
                                    <p
                                      className={`${fontsizing} font-weight-bold m-0 p-0 ${ismobile || isTablet ? "col-4" : ""
                                        } workerscomptoleft `}
                                    >
                                      Workers' Comp Code<span style={{ color: "red" }}>*</span>
                                      <a className="worker" href="#">
                                        <img
                                          src="https://cdn.onlinewebfonts.com/svg/img_151567.png"
                                          alt="info icon"
                                          style={{
                                            width: "10%",
                                            padding: "2%",
                                          }}
                                        />
                                      </a>
                                      <ul className="Legend">
                                        <li className="Legend-item">
                                          <span
                                            className="Legend-colorBox"
                                            style={{ backgroundColor: "green" }}
                                          ></span>
                                          <span className="Legend-label">
                                            Approved{" "}
                                          </span>
                                        </li>
                                        <li className="Legend-item">
                                          <span
                                            className="Legend-colorBox"
                                            style={{
                                              backgroundColor: "orange",
                                            }}
                                          ></span>
                                          <span className="Legend-label">
                                            Referred
                                          </span>
                                        </li>
                                        <li className="Legend-item">
                                          <span
                                            className="Legend-colorBox"
                                            style={{
                                              backgroundColor: "red",
                                            }}
                                          ></span>
                                          <span className="Legend-label">
                                            Declined
                                          </span>
                                        </li>
                                      </ul>
                                    </p>
                                  )}
                                  <ClassCodeDropDown
                                    newRatesModel={newRatesModel}
                                    setNewRatesModel={setNewRatesModel}
                                    selectedProgram={selectedProgram}
                                    effectiveDate={effectiveDate}
                                    stateIndex={stateIndex}
                                    classCodeIndex={classCodeIndex}
                                    classcode={classcode}
                                    emodStatesData={emodStatesData}
                                    carrierEligibility={carrierEligibility}
                                    setCarrierEligibility={
                                      setCarrierEligibility
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <SideSlider
                              list={classcode.newRatesResponse || []}
                              listToDisplay={listToDisplay}
                              displayHeaders={displayHeaders}
                              showRatesValues={
                                state.state.value !== "0" &&
                                  classcode?.classCode?.value !== "0"
                                  ? true
                                  : false
                              }
                              codeDesc={classcode?.classCode?.label}
                              state={state?.state?.value}
                              carrierEligibility={carrierEligibility}
                              selectedPeo={selectedPeo}
                              pm={pm}
                              selectedCarrierList={selectedCarrierList}
                            />

                            {newRatesModel.states.length > 1 && (
                              <div
                                className={
                                  ismobile || isTablet
                                    ? "col-12 d-flex align-items-end justify-content-center mt-3"
                                    : "col-1 d-flex align-items-end justify-content-center"
                                }
                              >
                                <img
                                  src={RemoveIcon}
                                  alt="-"
                                  className="newrates-img delete-row"
                                  onClick={() => {
                                    removeState(stateIndex);
                                    removeEmodStates();
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}{" "}
                      {ismobile || isTablet ? (
                        <hr style={{ color: "black" }} />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              <div
                className={` ${ismobile || isTablet ? "col-xl-12" : "row"} `}
              >
                <div className="col-sm-3 col-md-12 d-flex buttontoright ">
                  <span>Add Row&nbsp;</span>
                  <img
                    src={PlusIcon}
                    alt="+"
                    className="newrates-img add-row"
                    onClick={addState}
                  />
                </div>
              </div>
            </div>
            {/* {Object.keys(emodStatesData).length > 0 && (
            <div className="d-flex px-5 py-5 mb-2">
              <div className="emod-ques">
                <h4 className="emod-ques-heading">
                  <b>Experience Modification</b>
                </h4>
                {Object.keys(emodStatesData).map((e: any) => {
                  return (
                    <EmodQuestions
                      key={e}
                      state={e}
                      emodData={emodStatesData[e]}
                      updateEmodInfo={updateEmodInfo}
                      dontShowAddEmodSign={true}
                      getAllRates={getAllRates}
                    />
                  );
                })}
              </div>
            </div>
          )} */}
            <div className="row px-5 Spacing-rating">
              <div className="col-md-2">
                <label
                  htmlFor="raterNameField"
                  className="fs-6 font-weight-bold m-0 p-0"
                >
                  Name
                </label>
                <input
                  className="raterNameField w-100"
                  id="raterNameField"
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                />
              </div>
              <div className="col-md-1 d-flex align-items-end rater-save-btn">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleOnClickSave();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="row px-5 mt-5 no-gutters rater-action-buttons">
            <div className="col-md-1">
              {/* <div className="d-flex downloadbutton">
          <div className="mr-auto ml-5 mb-5 downloadbuttonmargin"> */}
              {!downloadDisable ? (
                <button className="btn btn-primary" disabled>
                  Download
                </button>
              ) : (
                <CSVLink
                  className="btn btn-primary"
                  filename={"Libertate Rater Data.csv"}
                  data={downloadData}
                  onClick={prepareDownloadData}
                >
                  Download
                </CSVLink>
              )}
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-warning onClickQuoteToolBtn"
                onClick={() => {
                  handleOnClickQuoteToolBtn();
                }}
              >
                Quote Tool
              </button>
              {quoteToolError && (
                <p style={{ fontSize: "14px", color: "red", marginTop: "1%" }}>
                  {quoteToolError}
                </p>
              )}
            </div>
            <div className="col-md-7"></div>
          </div>
          <div className="information px-5">
            <ul className="outer-list">
              <li>
                CA rates are for general guidance only and include the highest
                territory factored in for that carrier. CA rates are indicatory
                only & include the territory with the highest rating factor for
                that carrier. Actual rates may be lower based on Zip code
                selection.
              </li>
              <li>
                Rates for MCPs are based on MCP effective date and may not
                coincide with the master policy effective date.
              </li>
              <li>
                Net rates given in this file are derived from estimated payrolls
                at policy issuance. Final audit net rates will be derived from
                actual payrolls and may vary due to changes in payrolls,
                reclassifications, changes in mods or other premium algorithms
                adjustments made at audit.
              </li>
              <li>
                Status
                <ul className="inner-list">
                  <li>
                    TBD
                    <sup>
                      <b>*</b>
                    </sup>{" "}
                    - Please use the “Quote Tool” button to get the net rates.
                  </li>
                  <li>
                    EXCL
                    <sup>
                      <b>**</b>
                    </sup>{" "}
                    - The state-class code combination is not eligible for the
                    program.
                  </li>
                  <li>
                    N/A
                    <sup>
                      <b>***</b>
                    </sup>{" "}
                    - Please reach out to the tool administrator as the required
                    information is unavailable.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={updatePopUpData} />
        )}
        <Toaster />
      </div>
    </>
  );
};

function SideSlider(props: ISideSliderProps) {
  const [list, setList] = useState<any>([]);
  const [listToDisplay, setListToDisplay] = useState<any>([]);
  let listKeys = Object.keys(props.list);

  useEffect(() => {
    setList(props.list);
    setListToDisplay(props.listToDisplay);
  }, [props.list, props.listToDisplay]);

  let programRange = (headers) => {
    let peo = headers.split("-")[0];
    let carrier = headers.split("-")[1];
    try {
      let range = programMapping[headers]?.range
      let date = moment(programMapping[headers].range[0].from).format("MM-DD-YYYY")
      if (range.length > 0) {
        if (carrier === "Midwest Employers Casualty") {
          return (<p style={{ whiteSpace: "pre-wrap" }}>{`${peo + "-Midwest"} \n(${date})`}</p>)
        } else {
          return (<p style={{ whiteSpace: "pre-wrap" }}>{`${headers} \n(${date})`}</p>)
        }
      } else {
        return headers
      }
    } catch (err) {
      console.log("ERROR:", err)
      return headers
    }
  }

  return (
    <>
      <div
        className={` ${ismobile || isTablet ? "col-xl-12" : "col-8"
          }  pl-2 addingoverflow `}
      >
        <div
          className={`row  manrate_netrate h-100 align-items-end no-gutters ${listToDisplay.length >= PROGRAM_DISPLAY_COUNT
            ? "justify-content-center"
            : ""
            }`}
        >
          {list.map((rates: any, ratesIndex: any) => {
            let displayRateHeaders: Boolean;

            if (ismobile || isTablet) {
              displayRateHeaders = props.displayHeaders && ratesIndex === 0;
            } else {
              displayRateHeaders = props.displayHeaders;
            }

            let eligibleColor = "none";
            if (props.state !== "0" && props.codeDesc !== "--Select--") {
              const isEligible = props.carrierEligibility?.[props.state]?.[
                props.codeDesc
              ]?.[rates.carrier] as string;

              eligibleColor = colorMap[isEligible as keyof typeof colorMap];
            }
            if (props?.selectedCarrierList?.length > 0 && listToDisplay.includes(rates.program))
              return (
                <div
                  key={ratesIndex}
                  className={`${ismobile || isTablet ? "col-lg-12" : "col-3"
                    } pl-3 `}
                >
                  <div className="row align-items-end no-gutters listofrates ">
                    <div className=" mobileviewmin_net paddingcolumn w-100 ">
                      {ismobile || isTablet ? (
                        <div className="col-sm-3">
                          <span className="d-flex justify-content-start listofdatatodisplay">
                            {/* {listToDisplay[ratesIndex]} */}
                            {props.selectedPeo === "demo"
                              ? `Demo-${props.pm[
                                listToDisplay[ratesIndex]
                              ]?.carrier?.toUpperCase()}`
                              : programRange(listToDisplay[ratesIndex])}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={`${!(ismobile || isTablet) ? "col-6" : "col-sm-4"
                          } pr-1`}
                      >
                        {displayRateHeaders && (
                          <p
                            className={` ${fontsizing} font-weight-bold text-center m-0 p-0 padding_to_netrate`}
                          >
                            Man.Rate
                          </p>
                        )}
                        <span className="d-flex justify-content-center align-items-center bg-white rate-box">
                          {props.showRatesValues && !isNaN(rates.manualRate)
                            ? Number(rates.manualRate).toFixed(2)
                            : props.showRatesValues
                              ? rates.manualRate
                              : " "}
                        </span>
                      </div>

                      <div
                        className={`${!(ismobile || isTablet) ? "col-1" : "col-sm-1"
                          }  d-flex justify-content-center align-self-center pl-1 pr-1 dot`}
                      >
                        {props.showRatesValues && (
                          <span
                            style={{
                              backgroundColor: eligibleColor || "none",
                              height: "10px",
                              width: "10px",
                              display: "flex",
                              borderRadius: "50%",
                            }}
                          ></span>
                        )}
                      </div>
                      <div
                        className={`${!(ismobile || isTablet) ? "col-6" : "col-sm-4"
                          }   netratebottom pl-1`}
                      >
                        {displayRateHeaders && (
                          <p
                            className={`${fontsizing} font-weight-bold text-center m-0 p-0 padding_to_netrate text-nowrap`}
                          >
                            Net Rate
                          </p>
                        )}
                        <span className="d-flex justify-content-center align-items-center bg-white rate-box">
                          {props.showRatesValues && !isNaN(rates.netRate)
                            ? Number(rates.netRate).toFixed(4)
                            : props.showRatesValues
                              ? rates.netRate
                              : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </>
  );
}
