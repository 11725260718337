import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import HeaderSubPart from "./HeaderSubPart";

let libertatelogo = require("../../images/libertate.png");
let insurecomplogo = require("../../images/new-logo.png");
let altMarketLogo = require("../../images/alt_market_logo.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: "large",
      loggedInPeo: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      if (sessionData) {
        let userType = sessionData.attributes["custom:user_type"];
        if (userType === "peo") {
          let email = sessionData.attributes.email;
          let user_peo = email.split("@")[0];
          this.setState({ loggedInPeo: user_peo });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    let isMobile = window.innerWidth < 900;
    let { logoSize, loggedInPeo } = this.state;

    //Sharina asked to comment the libertate logo in dev libertate

    return (
      <>
        <nav
          id="navbarMain"
          className="new-navbar container-fluid  nav_fontsize "
          style={{ backgroundColor: "white", height: "auto", fontSize: "16px" }}
        >
          <div className="row justify-content-between align-items-center w-100 no-gutters">
            {process.env.REACT_APP_MODE === "prod" ? (
              <Link
                to={"/"}
                className="navbar-brand mx-0 d-flex align-items-center navlisting"
              >
                <img
                  className="imagesizelogo"
                  id="imagesizelogo"
                  style={
                    logoSize === "large"
                      ? { height: "100px", marginLeft: "2rem" }
                      : { height: "100px", marginLeft: "1.6rem" }
                  }
                  src={loggedInPeo === "ies" ? altMarketLogo : libertatelogo}
                  alt="libertate logo"
                />
              </Link>
            ) : (
              <div></div>
            )}

            {isMobile ? " " : <HeaderSubPart />}

            <Link
              to={"/"}
              className="navbar-brand mx-0 d-flex align-items-center"
              style={{ flexDirection: "column" }}
            >
              <img
                className="imagesizelogoicomp"
                id="imagesizelogoicomp"
                style={
                  logoSize === "large"
                    ? {
                        height: "70px",
                        marginRight: "2rem",
                      }
                    : {
                        height: "71px",
                        marginRight: "1rem",
                      }
                }
                src={insurecomplogo}
                alt="insurecomp logo"
              />
            </Link>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
